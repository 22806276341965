// plugins/admin-host.js
import Vue from "vue";

const legacyAdminHostMap = {
  local: "kr.localhost.streami.io:4000",
  frog: "kr.admin.gopax.frog.streami.io",
  dev: "kr.admin.gopax.dev.streami.io",
  testing: "kr.admin.gopax.testing.streami.io",
  qa: "kr.admin.gopax.qa.streami.co",
  staging: "kr.admin.gopax.staging.streami.io",
  prod: "admin.gopax.co.kr",
};
const newAdminHostMap = {
  "localhost:8000": "local",
  "admin2.gopax.frog.streami.io": "frog",
  "admin2.gopax.dev.streami.io": "dev",
  "admin2.gopax.testing.streami.io": "testing",
  "admin2.gopax.qa.streami.io": "qa",
  "admin2.gopax.staging.streami.io": "staging",
  "admin2.gopax.co.kr": "prod",
};
const gopaxHostMap = {
  "localhost:8000": "https://testing.gopax.co.kr/",
  "admin2.gopax.qa.streami.io": "https://qa.gopax.co.kr/",
  "admin2.gopax.dev.streami.io": "https://gopax.dev.streami.io/",
  "admin2.gopax.testing.streami.io": "https://testing.gopax.co.kr/",
  "admin2.gopax.staging.streami.io": "https://staging.gopax.co.kr/",
  "admin2.gopax.frog.streami.io": "https://frog.gopax.co.kr/",
  "admin2.gopax.co.kr": "https://www.gopax.co.kr/",
};

const currentHost = location.host;

class AdminHostMap {
  constructor() {
    this._legacyAdminHost = legacyAdminHostMap[newAdminHostMap[currentHost]];
    this._gopaxHost = gopaxHostMap[currentHost];
    this._isProd = newAdminHostMap[currentHost] === "prod"; // test: local;
  }

  getLegacyAdminHost() {
    return this._legacyAdminHost;
  }

  getGopaxHost() {
    return this._gopaxHost;
  }

  getIsProd() {
    return this._isProd;
  }
}

const AdminHostPlugin = {
  install: (Vue) => {
    const adminHost = new AdminHostMap();
    Vue.mixin({
      methods: {
        getLegacyAdminHost() {
          return adminHost.getLegacyAdminHost();
        },
        getGopaxHost() {
          return adminHost.getGopaxHost();
        },
        getIsProd() {
          return adminHost.getIsProd();
        },
      },
    });
  },
};

export function installAdminHost() {
  Vue.use(AdminHostPlugin);
}
