// plugins/extension.js
import Vue from "vue";
import moment from "moment";

class ExtensionMap {
  toMoneyString(number) {
    if (!number) return 0;
    return parseInt(number, 10).toLocaleString(undefined, {
      maximumFractionDigits: 8,
    });
  }

  toFormattedDateString(datetime) {
    if (!datetime) {
      return "";
    }
    return moment(datetime).format("YYYY-MM-DD HH:mm:ss");
  }
}

const ExtensionPlugin = {
  install: (Vue) => {
    const extensions = new ExtensionMap();
    Vue.mixin({
      methods: {
        toMoneyString(value) {
          return extensions.toMoneyString(value);
        },
        toFormattedDateString(value) {
          return extensions.toFormattedDateString(value);
        },
      },
    });
  },
};

export function installExtension() {
  Vue.use(ExtensionPlugin);
}
