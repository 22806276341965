import Vue from "vue";
import Router from "vue-router";
import { getRoutes } from "@/pages";
import store from "./store";

Vue.use(Router);

const routes = Object.values(getRoutes());

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/examples/pages",
      component: () => import("@/views/pages/Index"),
      children: [
        {
          name: "Lock",
          path: "lock",
          component: () => import("@/views/pages/Lock"),
        },
        {
          name: "Login",
          path: "login",
          component: () => import("@/views/pages/Login"),
        },
        {
          name: "Register",
          path: "register",
          component: () => import("@/views/pages/Register"),
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/views/dashboard/Index"),
      children: [
        ...routes,
        // Hello
        {
          name: "Hello",
          path: "hello",
          component: () => import("@/views/dashboard/pages/Hello"),
        },
        // Dashboard Demo
        {
          name: "DashboardDemo",
          path: "/examples/dashboard",
          component: () => import("@/views/dashboard/DashboardDemo"),
        },
        // Pages
        {
          name: "User Profile",
          path: "examples/pages/user",
          component: () => import("@/views/dashboard/pages/UserProfile"),
        },
        {
          name: "Timeline",
          path: "examples/pages/timeline",
          component: () => import("@/views/dashboard/pages/Timeline"),
        },
        // Components
        {
          name: "Buttons",
          path: "examples/components/buttons",
          component: () => import("@/views/dashboard/component/Buttons"),
        },
        {
          name: "Grid System",
          path: "examples/components/grid-system",
          component: () => import("@/views/dashboard/component/Grid"),
        },
        {
          name: "Tabs",
          path: "examples/components/tabs",
          component: () => import("@/views/dashboard/component/Tabs"),
        },
        {
          name: "Notifications",
          path: "examples/components/notifications",
          component: () => import("@/views/dashboard/component/Notifications"),
        },
        {
          name: "Icons",
          path: "examples/components/icons",
          component: () => import("@/views/dashboard/component/Icons"),
        },
        {
          name: "Typography",
          path: "examples/components/typography",
          component: () => import("@/views/dashboard/component/Typography"),
        },
        // Forms
        {
          name: "Regular Forms",
          path: "examples/forms/regular",
          component: () => import("@/views/dashboard/forms/RegularForms"),
        },
        {
          name: "Extended Forms",
          path: "examples/forms/extended",
          component: () => import("@/views/dashboard/forms/ExtendedForms"),
        },
        {
          name: "Validation Forms",
          path: "examples/forms/validation",
          component: () => import("@/views/dashboard/forms/ValidationForms"),
        },
        {
          name: "Wizard",
          path: "examples/forms/wizard",
          component: () => import("@/views/dashboard/forms/Wizard"),
        },
        // Tables
        {
          name: "Regular Tables",
          path: "examples/tables/regular-tables",
          component: () => import("@/views/dashboard/tables/RegularTables"),
        },
        {
          name: "Extended Tables",
          path: "examples/tables/extended-tables",
          component: () => import("@/views/dashboard/tables/ExtendedTables"),
        },
        {
          name: "Data Tabels",
          path: "examples/tables/data-tables",
          component: () => import("@/views/dashboard/tables/DataTables"),
        },
        // Root level
        {
          name: "Widgets",
          path: "examples/widgets",
          component: () => import("@/views/dashboard/Widgets"),
        },
        {
          name: "Charts",
          path: "examples/charts",
          component: () => import("@/views/dashboard/Charts"),
        },
        {
          name: "Calendar",
          path: "examples/calendar",
          component: () => import("@/views/dashboard/Calendar"),
        },
      ],
    },
    // {
    //   name: "404 Error",
    //   path: "/error",
    //   component: () => import("@/views/pages/Error"),
    // },
    {
      name: "404 Error",
      path: "*",
      component: () => import("@/views/pages/Error"),
    },
  ],
});

// Global Navigation Guards
router.beforeEach((to, from, next) => {
  const idList = to.matched
    .map((r) => (r.meta ? r.meta.id : null))
    .filter((id) => !!id);

  // allow if view id is not set
  if (idList.length === 0) {
    next();
    return;
  }

  const isAllowed = idList.some((id) => store.getters.isAllowedViewId(id));
  if (isAllowed) {
    next();
  } else {
    next("/");
  }
});

export default router;
