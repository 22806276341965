export default class PageManager {
  static _splitPath(path) {
    return path.split("/").filter((it) => !!it);
  }

  constructor() {
    this._routes = {};
    this._menus = [];
    this._viewIdSet = new Set();
  }

  _findParentMenu(path) {
    const paths = PageManager._splitPath(path);
    paths.pop();

    let parent = this._menus;
    paths.forEach((it) => {
      const found = parent.find(
        (child) => child.group && child.group.includes(it, `/${it}`)
      );
      if (!found) {
        throw new Error(`parent group not found. path=${path}`);
      }

      parent = found.children;
    });
    return parent;
  }

  /**
   * Register a new menu group.
   * @returns registered menu group. Returns undefined if parent path not found.
   */
  registerMenuGroup({ name, title, path, icon }) {
    const parent = this._findParentMenu(path);
    if (!parent) {
      return undefined;
    }

    const lastPathItem = PageManager._splitPath(path).pop();
    const isTopMenu = parent === this._menus;

    const groupItem = {
      group: isTopMenu ? `/${lastPathItem}` : lastPathItem,
      name,
      title,
      icon,
      children: [],
    };
    parent.push(groupItem);
    return groupItem;
  }

  /**
   * Register page component and menu item.
   */
  registerPageMenu({
    id,
    name,
    title,
    path,
    icon,
    component,
    development = false,
  }) {
    // add route
    this._routes[path] = {
      name,
      title,
      path,
      component,
      meta: {
        id,
        title,
      },
    };

    // add menu
    const parent = this._findParentMenu(path);
    if (parent) {
      const lastPathItem = PageManager._splitPath(path).pop();
      parent.push({
        viewId: id,
        icon,
        name,
        title,
        to: lastPathItem || "/",
      });
    } else {
      throw new Error(
        `failed to register menu. parent not found. path=${path}`
      );
    }

    const isDisplay =
      id && (!development || process.env.NODE_ENV === "development");

    // viewId
    if (isDisplay) {
      this._viewIdSet.add(id);
    }
  }

  /**
   * Register page component without menu item.
   */
  registerPage({ id, name, path, component }) {
    // add route
    this._routes[path] = {
      name,
      path,
      component,
      meta: {
        id,
      },
    };

    // viewId
    if (id) {
      this._viewIdSet.add(id);
    }
  }

  /**
   * Get routes for vue-router.
   */
  getRoutes() {
    return this._routes;
  }

  /**
   * Get drawer menus
   */
  getMenus() {
    return this._menus;
  }

  /**
   * Get all view id list
   */
  getViewIds() {
    return Array.from(this._viewIdSet);
  }
}
