import Vue from "vue";
import MSAL from "vue-msal";
import axios from "axios";

const AuthAxiosPlugin = {
  install(Vue, options) {
    Vue.mixin({
      methods: {
        authRequest(method, url, data, config, headers = {}) {
          // console.log("in authRequest,", { msal: this.$msal });
          const msalData = this.$msal.data;
          if (!msalData || !msalData.idToken) {
            console.error("$msal.data", msalData);
            throw new Error("$msal.data.idToken is empty");
          }
          if (!this.$msal.isAuthenticated()) {
            this.$msal.signIn();
          }
          return axios.request({
            method: method,
            url: url,
            data,
            ...config,
            headers: Object.assign(headers, getAuthHeaders(this.$msal)),
          });
        },
        authGet(url, config) {
          return this.authRequest("GET", url, undefined, config);
        },
        authDelete(url, config) {
          return this.authRequest("DELETE", url, undefined, config);
        },
        authPost(url, data, config) {
          return this.authRequest("POST", url, data, config);
        },
        authPut(url, data, config) {
          return this.authRequest("PUT", url, data, config);
        },
        authPostWithFiles(url, data, config) {
          return this.authRequest("POST", url, data, config, {
            "Content-Type": "multipart/form-data",
          });
        },
        callGrpcPost(service, method, data = "", config = undefined) {
          const request = {
            service: `co.streami.gopax.internal.service.admin.${service}`,
            method,
            data,
          };
          return this.authRequest(
            "POST",
            "/api/call-grpc-by-json",
            request,
            config
          );
        },
      },
    });
  },
};

export function installAuthAxiosPlugin() {
  Vue.use(AuthAxiosPlugin);
}

/**
 * 백엔드 서버로 전송할때 사용할 AAD 인증 헤더를 반환한다.
 */
export function getAuthHeaders(ctx) {
  return {
    Authorization: `Bearer ${ctx.data.idToken}`,
    AuthorizationAccess: `Bearer ${ctx.data.accessToken}`,
    AuthAdminEmail: ctx.data.user.userName,
  };
}

export async function initMSAL(store, onTokenListener) {
  const msalCfg = {
    auth: {
      clientId: process.env.VUE_APP_AAD_CLIENT_ID,
      redirectUri: process.env.VUE_APP_AAD_REDIRECT_URI,
      requireAuthOnInitialize: true,
      async onToken(ctx, error, response) {
        store.commit("SET_AUTH_CONTEXT", ctx);

        if (onTokenListener) {
          onTokenListener(ctx);
        }
      },
      beforeSignOut(ctx) {
        store.commit("SET_AUTH_CONTEXT", null);
      },
    },
    cache: {
      // vue-msal.js 에서는 idToken을 저장하지 않기 때문에 cacheLocation을
      // sessionStorage로 설정해야 새로운 세션에서 idToken을 새로 받아올 수 있음
      cacheLocation: "sessionStorage",
      // cookie에 인증정보를 저장하는 경우,
      // 새로운 세션 사용시 $msal.data.idToken = "" 상태가 되는 문제가 있음.
      storeAuthStateInCookie: false,
    },
  };

  try {
    // Backend에서 AAD Client 정보를 읽어와 업데이트
    const res = await axios.get("/api/config/aad");
    console.log("in initMSAL,", { res });
    Object.assign(msalCfg.auth, {
      clientId: res.data.clientId,
      tenantId: res.data.clientTenantId || "common",
      redirectUri: res.data.redirectUri,
    });
  } catch (e) {
    console.error(`failed to get AAD config from backend`, e);
  }
  Vue.use(MSAL, msalCfg);
  Vue.use(AuthAxiosPlugin);
}
