import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    barColor: "rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)",
    barImage: require("@/assets/img/sidebar-1.jpg"),
    drawer: null,
    authenticationContext: null,
    allowedViewActions: undefined,
    pageManager: null,
  },
  mutations: {
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload;
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
    SET_SCRIM(state, payload) {
      state.barColor = payload;
    },
    SET_AUTH_CONTEXT(state, payload) {
      state.authenticationContext = payload;
    },
    SET_ALLOWED_VIEW_ACTIONS(state, payload) {
      state.allowedViewActions = payload;
    },
    SET_PAGE_MANAGER(state, payload) {
      state.pageManager = payload;
    },
  },
  getters: {
    loginUserName: (state) => {
      const { authenticationContext } = state;
      if (authenticationContext) {
        return authenticationContext.data.user.name;
      } else {
        return "Guest";
      }
    },
    /**
     * 현재 로그인한 사용자가 접근 가능한 화면 목록에 viewId가 포함되어 있는지 확인한다.
     */
    isAllowedViewId: (state) => (viewId) => {
      const allowedViewActions = state.allowedViewActions;

      // AD 로그인이 완료되지 않은 상태인 경우, 일단 모두 허용
      // AD 로그인 완료 후, 다시 호출되면서 권한이 없는 경우 리다이렉션되기 때문에 문제없음
      if (allowedViewActions == null) {
        return true;
      }

      const allowedActions = allowedViewActions[viewId];
      return Array.isArray(allowedActions) && allowedActions.length > 0;
    },

    /**
     * viewId 에서 현재 로그인한 사용자에게 허용된 액션 목록을 반환한다.
     */
    getViewAllowedActions: (state) => (viewId) => {
      const actions = state.allowedViewActions;
      return (actions && actions[viewId]) || [];
    },
  },
});
