import PageManager from "@/PageManager";
import i18n from "@/i18n";
import store from "@/store";
import AdminConstants from "@/views/dashboard/pages/admin/constants";
import RequestConstants from "@/views/dashboard/pages/request/constants";
import ContentsConstants from "@/views/pages/contents/constants";
import UserConstants from "@/views/pages/user/constants";
import CorporateConstants from "@/views/dashboard/pages/corporate/constants";
import LandingPageContstants from "@/views/dashboard/pages/landingpage/constants";

function init(pm) {
  // pm.registerPageMenu({
  //   id: "Dashboard",
  //   development: true,
  //   name: "dashboard",
  //   title: i18n.t("page.dashboard"),
  //   path: "/",
  //   icon: "mdi-view-dashboard",
  //   component: () => import("@/views/dashboard/Dashboard"),
  // });

  // AML group
  // pm.registerMenuGroup({
  //   name: "aml",
  //   title: i18n.t("group.aml"),
  //   path: "/aml",
  //   icon: "mdi-account-alert",
  // });
  // pm.registerPageMenu({
  //   id: "AmlUserList",
  //   development: true,
  //   name: "amlList",
  //   title: i18n.t("page.aml-list"),
  //   path: "/aml/list",
  //   icon: "mdi-account-multiple-outline",
  //   component: () => import("@/views/dashboard/pages/aml/AmlUserListPage"),
  // });

  // User group
  pm.registerMenuGroup({
    name: "user",
    title: i18n.t("group.users"),
    path: "/user",
    icon: "mdi-account-multiple-outline",
    // icon: "mdi-account",
  });
  // TODO: will make for based `user_kyc`
  // pm.registerPageMenu({
  //   id: UserConstants.VIEW_USER_INDEX,
  //   development: true,
  //   name: "userList",
  //   title: i18n.t("page.user-list"),
  //   path: "/user/list",
  //   // icon: "mdi-account-multiple-outline",
  //   component: () => import("@/views/pages/user/UserIndex"),
  // });
  pm.registerPageMenu({
    development: true,
    id: UserConstants.VIEW_TRADE_FEE_RATE,
    name: "tradeFeeRate",
    title: i18n.t("trade-fee-rate"),
    path: "/user/trade-fee-rate",
    component: () => import("@/views/pages/user/TradeFeeRate"),
  });
  pm.registerPage({
    development: true,
    id: "view:TradeFeeRate",
    name: "tradeFeeRateEdit",
    title: i18n.t("trade-fee-rate"),
    path: "/user/trade-fee-rate/:id",
    component: () => import("@/views/pages/user/TradeFeeRate"),
  });
  pm.registerPageMenu({
    id: UserConstants.VIEW_SELF_EMPLOYED_USER,
    name: "selfEmployedUserList",
    title: i18n.t("page.self-employed-user-list"),
    path: "/user/self-employed-user",
    icon: "-",
    component: () => import("@/views/pages/user/SelfEmployedUser"),
  });
  pm.registerPageMenu({
    development: true,
    id: UserConstants.VIEW_SEARCH_IP,
    name: "searchIp",
    title: i18n.t("search-ip"),
    path: "/user/search-ip",
    component: () => import("@/views/pages/user/SearchIp"),
  });

  // Corporate group
  pm.registerMenuGroup({
    name: "corporate",
    title: i18n.t("group.corporate"),
    path: "/corporate",
    icon: "mdi-account-multiple-outline",
    // icon: "mdi-account",
  });
  pm.registerPageMenu({
    id: CorporateConstants.VIEW_CORPORATE_ACCOUNT,
    development: true,
    name: "corporateAccountList",
    title: i18n.t("page.corporate-account-list"),
    path: "/corporate/corporate-account",
    component: () =>
      import("@/views/dashboard/pages/corporate/CorporateAccount"),
  });
  pm.registerPageMenu({
    id: CorporateConstants.VIEW_CORPORATE,
    development: true,
    name: "corporateList",
    title: i18n.t("page.corporate-list"),
    path: "/corporate/corporate-info",
    component: () => import("@/views/dashboard/pages/corporate/Corporate"),
  });
  pm.registerPage({
    id: CorporateConstants.VIEW_CORPORATE_CREATE,
    name: "corporateCreate",
    path: "/corporate/corporate-info/:id/create",
    component: () => import("@/views/dashboard/pages/corporate/Corporate"),
  });

  // request
  pm.registerMenuGroup({
    name: "request",
    title: i18n.t("group.request"),
    path: "/request",
    icon: "mdi-alpha-r-box",
  });
  pm.registerPageMenu({
    development: true,
    id: RequestConstants.VIEW_REQUEST,
    name: "requestRequest",
    title: i18n.t("page.request"),
    path: "/request/request",
    // icon: "mdi-pencil-box-multiple-outline",
    component: () => import("@/views/dashboard/pages/request/RequestPage"),
  });
  pm.registerPageMenu({
    development: true,
    id: RequestConstants.VIEW_APPROVE,
    name: "requestApprove",
    title: i18n.t("page.approve"),
    path: "/request/approve",
    // icon: "mdi-checkbox-multiple-marked-outline",
    component: () => import("@/views/dashboard/pages/request/ApprovePage"),
  });

  // Admin group
  pm.registerMenuGroup({
    name: "admin",
    title: i18n.t("group.admin"),
    path: "/admin",
    icon: "mdi-shield-key",
  });
  pm.registerPageMenu({
    id: AdminConstants.VIEW_PERMISSION,
    name: "adminPermission",
    title: i18n.t("page.permission"),
    path: "/admin/permission",
    // icon: "mdi-key-chain",
    component: () => import("@/views/dashboard/pages/admin/PermissionPage"),
  });

  // Contents
  pm.registerMenuGroup({
    name: "contents",
    title: i18n.t("contents"),
    path: "/contents",
    icon: "mdi-file-document-edit",
  });
  pm.registerPageMenu({
    development: true,
    id: ContentsConstants.VIEW_EMAIL_INDEX,
    name: "contentsEmail",
    title: i18n.t("contents-email"),
    path: "/contents/contents-email",
    component: () => import("@/views/pages/contents/ContentsEmailIndex"),
  });
  pm.registerPage({
    id: ContentsConstants.VIEW_EMAIL_CREATE,
    name: "contentsEmailCreate",
    title: `${i18n.t("contents-email")}ABC`,
    path: "/contents/contents-email/create",
    component: () => import("@/views/pages/contents/ContentsEmailCreate"),
  });
  pm.registerPage({
    id: ContentsConstants.VIEW_EMAIL_SHOW,
    name: "contentsEmailShow",
    path: "/contents/contents-email/:id",
    component: () => import("@/views/pages/contents/ContentsEmailShow"),
  });
  pm.registerPage({
    id: ContentsConstants.VIEW_EMAIL_EDIT,
    name: "contentsEmailEdit",
    path: "/contents/contents-email/:id/edit",
    component: () => import("@/views/pages/contents/ContentsEmailEdit"),
  });
  pm.registerMenuGroup({
    name: "landingPage",
    title: i18n.t("group.landing-page"),
    path: "/landing-page",
    icon: "mdi-account-multiple-outline",
  });
  pm.registerPageMenu({
    id: LandingPageContstants.VIEW_LANDING_PAGE_CONTENTS,
    name: "landingPageContents",
    title: i18n.t("landing-page-content"),
    path: "/landing-page/contents",
    component: () =>
      import("@/views/dashboard/pages/landingpage/LandingPageContents"),
  });
}

const pageManager = new PageManager();
init(pageManager);
store.commit("SET_PAGE_MANAGER", pageManager);

export function getMenus() {
  return pageManager.getMenus();
}

export function getRoutes() {
  return pageManager.getRoutes();
}

export function getViewIds() {
  return pageManager.getViewIds();
}
