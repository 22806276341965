import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import {
  getAuthHeaders,
  initMSAL,
  installAuthAxiosPlugin,
} from "./plugins/msal";
import "./plugins/base";
import "./plugins/chartist";
import "./plugins/vee-validate";
import vuetify from "./plugins/vuetify";
import { installAdminHost } from "./plugins/admin-host";
import i18n from "./i18n";
import swal from "./swal";
import axios from "axios";
import { installExtension } from "./plugins/extension";

Vue.config.productionTip = false;

/**
 * AAD 로그인 성공시 호출될 리스너 함수
 */
const onTokenListener = async (ctx) => {
  // 로그인 사용자가 접근 가능한 viewId 목록을 가져와 스토어에 저장한다.
  try {
    const res = await axios.get("/api/auth/perm/views/allowed", {
      headers: getAuthHeaders(ctx),
    });
    const allowedViewActions = res.data.data;
    store.commit("SET_ALLOWED_VIEW_ACTIONS", allowedViewActions);
  } catch (e) {
    console.error(e);
    store.commit("SET_ALLOWED_VIEW_ACTIONS", null);
  }
};

console.log(`NODE_ENV: ${process.env.NODE_ENV}`);

// AAD 연동 초기화
initMSAL(store, onTokenListener).then(() => console.log("MSAL initialized"));

installAuthAxiosPlugin();

installAdminHost();

installExtension();

new Vue({
  router,
  store,
  vuetify,
  i18n,
  swal,
  render: (h) => h(App),
}).$mount("#app");
